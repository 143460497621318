/* eslint-disable max-len */
/* eslint-disable quotes */
import EventsAPI from "../../../shared/modules/EventsAPI";
import PropTypes from "prop-types";
import React from "react";
import {withCookies} from "react-cookie";

const EcoSection = ({cookies, enableJuly2024Updates}) => {
  const fastlyImageCdnUrl = "https://karat-herokuapp-com.freetls.fastly.net/s3media/site/homepage/2024/phase1";
  const points = [
    {
      "altText": "Reclaimed materials",
      "imageName": "reclaimed-materials.svg",
      "text": "Pre-owned collections and select pieces made from reclaimed materials"
    },
    {
      "altText": "Recyclable and reusable packaging",
      "imageName": "recycle-box.svg",
      "text": "Recyclable and reusable&nbsp;packaging"
    },
    {
      "altText": "Reducing and repurposing",
      "imageName": "housing.svg",
      "text": "Reducing and repurposing our warehouse plastic"
    },
    {
      "altText": "Offsetting carbon",
      "imageName": "carbon.svg",
      "text": "Offsetting carbon from&nbsp;shipments"
    }
  ];

  const handleClick = (event) => {
    event.preventDefault();
    window.analytics.track('Clicked Bottom Homepage CTA: Shop Now');
    EventsAPI.trackContactEvent('clicked_shop_now', 'clicked_from: bottom homepage cta', cookies.get('anonymous_contact_id'));
    window.location = '/jewelry';
  };

  return (
    <section
      className="relative bg-center bg-no-repeat bg-cover bg-gradient-two"
      id="eco"
    >
      <div className="inner-container">
        <div className="flex flex-col items-center mx-auto text-center max-w-[1060px] py-[80px] md:py-[60px] gap-[56px]">
          <div className="flex flex-col">
            <h2 className="styled_h1 flex flex-col gap-2 w-72 mx-auto 640:block 640:w-full">
              <span>
              Wear More,
              </span>
              <em>
              Waste Less
              </em>
            </h2>
            <p className="mx-auto my-0 max-w-96 1024:max-w-[460px]">
              <span className="block leading-7">
                Learn how we are reducing waste and enhancing circularity in jewelry.
              </span>
            </p>
          </div>
          <div className="space-y-8">
            <div className="grid grid-col-1 md:grid-cols-4 gap-x-[15px] gap-y-[60px] md:gap-y-0">
              {points.map((point, index) => (
                <div
                  className="flex flex-col gap-4 items-center justify-start md:max-w-[230px]"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}-${Math.random()}`}
                >
                  <img
                    alt={point.altText}
                    className="h-[68px]"
                    src={`${fastlyImageCdnUrl}/${point.imageName}`}
                  />
                  <p className="max-w-60 mx-auto">
                    <span
                      className="block text-xl leading-6"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{"__html": point.text}}
                    />
                  </p>
                </div>
              ))}
            </div>
            {enableJuly2024Updates &&
            <div>
              <a
                alt="Shop Now"
                className="button-outlined-white shop-now-button"
                href="/jewelry"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={handleClick}
              >
                Shop Now
              </a>
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
};

EcoSection.propTypes = {
  "cookies": PropTypes.object.isRequired,
  "enableJuly2024Updates": PropTypes.bool.isRequired
};

export default withCookies(EcoSection);
