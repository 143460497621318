import "core-js/stable";
import "regenerator-runtime/runtime";
import CarouselGrid from "../bundles/Wishlist/components/CarouselGrid";
import EmailFooterCapture from "../bundles/Site/components/EmailFooterCapture";
import EmailSmsCapture from "../bundles/Site/components/EmailSmsCapture";
import HomepageBelowTheFold from "../bundles/LoggedOutHomePage/components/HomepageBelowTheFold";
import PromoCodeBanner from "../bundles/Site/components/PromoCodeBanner";
import ReactOnRails from "react-on-rails";

ReactOnRails.register({
  CarouselGrid,
  EmailFooterCapture,
  EmailSmsCapture,
  HomepageBelowTheFold,
  PromoCodeBanner
});
