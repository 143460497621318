/* eslint-disable max-len */
/* eslint-disable quotes */
import EcoSection from "./EcoSection";
import PropTypes from "prop-types";
import React from "react";
import WhySection from "./WhySection";

const HomepageBelowTheFold = ({enableJuly2024Updates}) => (
  <div>
    <WhySection enableJuly2024Updates={enableJuly2024Updates} />
    <EcoSection enableJuly2024Updates={enableJuly2024Updates} />
  </div>
);

HomepageBelowTheFold.propTypes = {"enableJuly2024Updates": PropTypes.bool.isRequired};

// eslint-disable-next-line react/display-name, react/no-multi-comp
export default props => <HomepageBelowTheFold {...props} />;
