/* eslint-disable max-len */
/* eslint-disable quotes */
import EventsAPI from "../../../shared/modules/EventsAPI";
import PropTypes from "prop-types";
import React from "react";
import {withCookies} from "react-cookie";

const WhySection = ({cookies, enableJuly2024Updates}) => {
  const points = [
    'Discover a curated collection of on-trend jewelry from top designers.',
    'Wear more, waste less. Our collections include pre-loved jewelry, styles made from reclaimed materials and designers that share your values.',
    'Demi-fine and fine jewelry affordably priced to bring you high quality styles without the high cost.'
  ];

  const pointsFor2024 = [
    'Expertly Curated. Discover the latest demi-fine and fine jewelry styles from popular designers and a wide range of brands.',
    'Wear More, Waste Less. Our collections include pre-loved jewelry, styles made from reclaimed materials and designers that share your values.',
    'Exclusive Selection. Access exclusive collections of pre-loved and designer styles found nowhere else.'
  ];

  const pointsToDisplay = enableJuly2024Updates ? pointsFor2024 : points;

  const handleClick = (event) => {
    event.preventDefault();
    window.analytics.track('Clicked Why section Homepage CTA: Shop Now');
    EventsAPI.trackContactEvent('clicked_shop_now', 'clicked_from: why section on homepage', cookies.get('anonymous_contact_id'));
    window.location = '/jewelry';
  };

  return (
    <section
      className="bg-white"
      id="why"
    >
      <div className="inner-container">
        <div
          className="grid grid-cols-1 768:grid-cols-2 gap-10 max-w-[1060px] mx-auto py-10 768:pt-8 768:pb-20 768:gap-y-0"
        >
          <div className="flex flex-col justify-center items-start 768:max-w-[462px]">
            <h2 className="styled_h1">
              {"Why You'll Love Rocksbox"}
            </h2>
            <div className="space-y-4">
              {pointsToDisplay.map((point, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={`${index}-${Math.random()}`}>
                  <span
                    className="block text-5 leading-[26px]"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{"__html": point}}
                  />
                </p>
              ))}
            </div>
            <a
              alt="Shop Now"
              className="mt-6 button btn-primary"
              href="/jewelry"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={handleClick}
            >
              Shop Now
            </a>
          </div>
          <div className="flex items-center justify-center 768:justify-end">
            <img
              alt="Model with pendant"
              src="https://karat-herokuapp-com.freetls.fastly.net/s3media/site/homepage/2024/phase1/model-w-pendant.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

WhySection.propTypes = {
  "cookies": PropTypes.object.isRequired,
  "enableJuly2024Updates": PropTypes.bool.isRequired
};

export default withCookies(WhySection);
